<template>
  <div v-html="html" />
</template>

<script>
export default {
  name: 'RenderHtml',
  props: {
    html: {
      type: String,
      default: '',
    },
  },
};
</script>
